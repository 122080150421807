<template>
    <div class="shop_main">
        <div class="shopBuyingIndexFilter supply">
            <el-select v-model="listQuery.priceStatus" placeholder="请选择" @change="toChangePriceStatus">
                <el-option label="所有价格类型" value=""></el-option>
                <el-option label="可议价" value="1"></el-option>
                <el-option label="不可议价" value="2"></el-option>
                <el-option label="面议" value="3"></el-option>
            </el-select>

            <el-button @click="toChangeCreatedAt">
                信息发布时间
                <img src="../../../assets/img/supply_filter_down_on.png" v-if="listQuery.createdAtType === '1'" />
                <img src="../../../assets/img/supply_filter_down.png" v-if="listQuery.createdAtType === '2' || listQuery.createdAtType === ''" />
                <div class="line"></div>
                <img src="../../../assets/img/supply_filter_up.png" v-if="listQuery.createdAtType === '1' || listQuery.createdAtType === ''" />
                <img src="../../../assets/img/supply_filter_up_on.png" v-if="listQuery.createdAtType === '2'" />
            </el-button>

            <el-button @click="toChangeStopAt">
                信息截止时间
                <img src="../../../assets/img/supply_filter_down_on.png" v-if="listQuery.stopAtType === '1'"/>
                <img src="../../../assets/img/supply_filter_down.png" v-if="listQuery.stopAtType === '2' || listQuery.stopAtType === ''"/>
                <div class="line"></div>
                <img src="../../../assets/img/supply_filter_up.png" v-if="listQuery.stopAtType === '1' || listQuery.stopAtType === ''"/>
                <img src="../../../assets/img/supply_filter_up_on.png" v-if="listQuery.stopAtType === '2'"/>
            </el-button>
        </div>

        <div class="shopBuyingIndexList">
            <infoItem v-for="(item, index) in listData" :key="index" :item="item"/>
        </div>

        <noDataView v-if="listData.length === 0" />

        <div class="pl24 pr24 pb32">
            <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" :isSupply="true" @pageChange="pageChange" />
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getSupply} from "../../../api/shop/supplyBuying";

  const noDataView = () => import('../../../components/noDataView')
  const pagination = () => import('../../../components/pagination/shop')
  const infoItem = () => import('../../../components/layout/shop/supplyBuying/infoItem')
  export default {
    name: "SupplyIndex",
    data () {
      return {
        listQuery: {
          title: '',
          goodsName: '',
          content: '',
          code: '',
          priceStatus: '', // 价格类型1-可议价 2-不可议价 3-面议
          createdAtType: '', // 发布时间 1倒叙 2正叙
          stopAtType: '', // 截止时间 1倒叙 2正叙
          page: 1,
          pageSize: 12
        },
        value: '',
        listData: [],
        pages: {
          total: 100,
          pages: 5
        },
        isRequest: false
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
        supplyKeyword: state => state.user.supplyKeyword,
      })
    },
    watch: {
      supplyKeyword (newVal) {
        this.toSearch(newVal)
      }
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.$store.commit('changeScrollTop');
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.listQuery.priceStatus = ''
        this.listQuery.createdAtType = ''
        this.listQuery.stopAtType = ''
      }
      if (this.listKeepAlive.isQueryList) {
        this.getList()
      }
    },
    methods: {
      toSearch (newVal) {
        this.listQuery.page = 1
        this.listQuery.priceStatus = ''
        this.listQuery.createdAtType = ''
        this.listQuery.stopAtType = ''
        this.listQuery.title = newVal
        this.listQuery.goodsName = newVal
        this.listQuery.content = newVal
        this.listQuery.code = newVal
        this.getList()
      },
      getList () {
        getSupply(this.listQuery).then(res => {
          if (res.code === 0) {
            this.listData = res.data.list
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        })
      },
      pageChange (e) {
        this.listQuery.page = e.current
        this.listQuery.pageSize = e.size
        this.getList()
      },
      toChangeCreatedAt () {
        this.listQuery.stopAtType = ''
        if (this.listQuery.createdAtType === '1') {
          this.listQuery.createdAtType = '2'
        } else {
          this.listQuery.createdAtType = '1'
        }

        this.listQuery.page = 1
        this.getList()
      },
      toChangeStopAt () {
        this.listQuery.createdAtType = ''
        if (this.listQuery.stopAtType === '1') {
          this.listQuery.stopAtType = '2'
        } else {
          this.listQuery.stopAtType = '1'
        }

        this.listQuery.page = 1
        this.getList()
      },
      toChangePriceStatus () {
        this.listQuery.page = 1
        this.getList()
      }
    },
    destroyed() {
      this.$store.commit('changeSupplyKeyword', '')
    },
    components: {
      pagination,
      noDataView,
      infoItem
    }
  }
</script>

<style scoped>
    .el-select-dropdown__item.selected{ color: #FA8534}
</style>
