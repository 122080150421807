import {request} from '@/utils/request'

// 列表
export function getBuying(data) {
  return request('GET','/shop/purchase', data, true)
}

// 详情
export function getBuyingInfo(id) {
  return request('GET','/shop/purchase/' + id, {}, true)
}

// 已报价详情
export function getBuyingOfferInfo(id) {
  return request('GET','/shop/purchase/quotedPrice/' + id, {}, true)
}

// 侧边详情
export function getBuyingCompany(id) {
  return request('GET','/shop/purchase/sidebar/' + id, {}, true)
}

// 关注
export function postCompanyAttention(id, isAttention) {
  return request('POST','/shop/purchase/' + id + '/' + isAttention, {}, true)
}

// 报价
export function postBuyingOffer(data) {
  return request('POST','/shop/purchase', data, true)
}

// 报价单
export function getMyOffer(data) {
  return request('GET','/shop/quotedPrice', data, true)
}

// 报价单详情
export function getMyOfferInfo(id) {
  return request('GET','/shop/quotedPrice/' + id, {}, true)
}


// 单位
export function getUnit(data) {
  return request('GET','/shop/Unit', data, true)
}

// 列表
export function getSupply(data) {
  return request('GET','/shop/provision', data, true)
}

// 列表
export function getSupplyInfo(id) {
  return request('GET','/shop/provision/' + id, '', true)
}

// 关注
export function getAttention(data) {
  return request('GET','/shop/quotedPrice/attention/shopPurchase', data, true)
}

// 关注
export function getAttentionInfo(data) {
  return request('GET','/shop/quotedPrice/attention/purchaseInfo', data, true)
}
